import clsx from "clsx";
import { useAtomValue } from "jotai";
import pluralize from "pluralize";
import { projectConstantsAtom, projectsLoadable } from "../../jotai/projects";
import { PageSection, Table, Typography } from "../../library";
import { useShowSidebar } from "../../shared/Sidebar/useShowSidebar";
import { goToURL } from "../../utils";
import useProjectsTableColumn from "./useProjectsTableColumns";
import { getProjectDetailURL } from "./utils";

export default function AllProjects() {
  const showSidebar = useShowSidebar();
  const columns = useProjectsTableColumn();
  const projectsList = useAtomValue(projectsLoadable);
  const { name } = useAtomValue(projectConstantsAtom);
  return (
    <PageSection>
      <div
        className={clsx("grid gap-10 pb-10", {
          "pt-12": showSidebar,
        })}
      >
        <Typography
          size="sm"
          variant="display"
          color="brand.boldest.enabled"
          className="!font-normal"
        >
          Your {pluralize(name)}
        </Typography>
        <Table
          isLoading={projectsList.state === "loading"}
          data={projectsList.state === "hasData" ? projectsList.data : []}
          columns={columns}
          getId={({ id }) => id}
          onRowClick={({ id }) => goToURL(getProjectDetailURL(id))}
        />
      </div>
    </PageSection>
  );
}
