import clsx from "clsx";
import { useAtomValue } from "jotai";
import { type ReactNode, useEffect, useMemo, useState } from "react";
import { formatSearchPageParams } from "../../components/ContractSearch/utils";
import useRequestID from "../../hooks/useRequestID";
import { projectContextState } from "../../jotai/projects";
import {
  contractSearchParamsState,
  searchQueryState,
} from "../../jotai/search";
import { isFeatureEnabled } from "../../utils/features";
import { trackSearchFilterToggle } from "../../utils/tracking";
import { useShowSidebar } from "../Sidebar/useShowSidebar";
import ProjectSearchContent from "./ProjectSearchContent";
import ProjectSupplierListSidebar from "./ProjectSupplierListSidebar";
import SearchDebugInfo from "./SearchDebugInfo";
import SearchPageSidebar from "./SearchPageSidebar";
import SearchFilterHeader from "./SearchResults/SearchFilterHeader";
import SearchResults from "./SearchResults/SearchResults";
import SearchResultsHeader from "./SearchResults/SearchResultsHeader";
import type { OnFilterChangeFn } from "./SearchResults/types";

// `top-34` is the combined height of Header (`h-20`), spacing (`gap-6`), and Tabs (`h-8`)
// `[calc(100vh-9rem)]` is the height of the sidebar minus Header and Tabs height
const getRequestSidebarClass = (showSidebar: boolean) =>
  clsx(
    "sticky col-span-4 hidden h-[calc(100vh-9rem)] overflow-y-auto lg:block",
    {
      "top-20": showSidebar,
      "top-34 lg:col-start-1": !showSidebar,
    }
  );

function SearchPageContainer({
  header,
  children,
}: { header?: ReactNode; children: ReactNode }) {
  return (
    <div className="contract-search grid gap-8 w-full mt-6 mb-20">
      {header}
      <div className="grid grid-cols-4 md:grid-cols-12 gap-x-4 md:gap-x-6 items-start">
        {children}
      </div>
    </div>
  );
}

export default function SearchPage() {
  const showSidebar = useShowSidebar();
  const searchParams = useAtomValue(contractSearchParamsState);
  const query = useAtomValue(searchQueryState);
  const requestID = useRequestID();
  const project = useAtomValue(projectContextState);
  const params = useMemo(
    () => formatSearchPageParams(searchParams),
    [searchParams]
  );
  const [filters, setFilters] = useState(params.filters || []);

  const onFilterChange: OnFilterChangeFn = (params) =>
    trackSearchFilterToggle({
      ...params,
      query,
      requestID,
      projectId: project?.id,
    });

  useEffect(() => {
    setFilters(params.filters || []);
  }, [params.filters]);

  if (isFeatureEnabled("projectBrowsing") && project?.id) {
    const hasRequest = !!project.purchaseRequest;
    return (
      <SearchPageContainer>
        {hasRequest && !showSidebar && (
          <div className={getRequestSidebarClass(showSidebar)}>
            <ProjectSupplierListSidebar />
          </div>
        )}
        <div
          className={clsx("grid col-start-1 col-span-full lg:col-span-8", {
            "lg:col-start-5": hasRequest && !showSidebar,
          })}
        >
          <ProjectSearchContent
            filters={filters}
            onFilterChange={onFilterChange}
            setFilters={setFilters}
            params={params}
            option={project.purchaseRequest?.selectedOption}
            headerClassName={clsx({
              "top-14": showSidebar,
              "top-28": !showSidebar,
            })}
          />
        </div>
        {hasRequest && showSidebar && (
          <div className={getRequestSidebarClass(showSidebar)}>
            <ProjectSupplierListSidebar />
          </div>
        )}
        {!hasRequest && (
          <div className="col-span-4 hidden xl:col-start-9 space-y-6 lg:block">
            <SearchPageSidebar />
          </div>
        )}
      </SearchPageContainer>
    );
  }

  return (
    <SearchPageContainer
      header={
        showSidebar ? null : (
          <SearchFilterHeader
            filters={filters}
            setFilters={setFilters}
            onFilterChange={onFilterChange}
          />
        )
      }
    >
      <div className="grid col-start-1 col-span-full lg:col-end-9 gap-6">
        <SearchResultsHeader params={params}>
          <SearchDebugInfo />
        </SearchResultsHeader>
        <SearchResults />
      </div>
      <div className="col-span-4 hidden xl:col-start-9 space-y-6 lg:block">
        <SearchPageSidebar />
      </div>
    </SearchPageContainer>
  );
}
