import clsx from "clsx";
import { useShowSidebar } from "../Sidebar/useShowSidebar";

export const HEADER_CONTAINER_CLASS =
  "z-3 w-full sticky h-20 flex flex-row items-center justify-between top-0 gap-4";

export const MAIN_HEADER_CONTAINER_CLASS = clsx(
  HEADER_CONTAINER_CLASS,
  "bg-cp-violet-200 border-solid border-b border-neutral-120"
);

export function useHeaderTopClass() {
  const showSidebar = useShowSidebar();
  return showSidebar ? "top-0" : "top-20";
}
