import clsx from "clsx";
import { useAtom, useSetAtom } from "jotai";
import type { RefObject } from "react";

import imageIcon from "../../../img/icons/image.svg";

import type { SupplierProductGroup } from "../../generated";
import {
  productLastSearchQueryState,
  selectedProductGroupState,
} from "../../jotai/products";
import { BookmarkLink, Typography } from "../../library";
import ImageWithFallback from "../../library/ImageWithFallback";
import { borderColorClass } from "../../utils/colors";

interface ProductGroupProps {
  productGroup: SupplierProductGroup;
  productsRef?: RefObject<HTMLDivElement>;
  trackSelectProductGroup: (productGroupId: number) => void;
}

export default function ProductGroup({
  productGroup,
  productsRef,
  trackSelectProductGroup,
}: ProductGroupProps) {
  const [selectedProductGroup, setSelectedProductGroup] = useAtom(
    selectedProductGroupState
  );
  const setProductLastSearchQuery = useSetAtom(productLastSearchQueryState);
  const isSelected = productGroup === selectedProductGroup;

  const selectProductGroup = () => {
    setSelectedProductGroup(productGroup);
    setProductLastSearchQuery("");
    if (productsRef?.current) {
      productsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    trackSelectProductGroup(productGroup.id);
  };

  return (
    <div
      className={clsx(
        "flex-1 rounded-2xl border border-solid divide-y inline-flex flex-col justify-start items-start overflow-hidden cursor-pointer",
        isSelected
          ? borderColorClass.brand.bold.enabled
          : borderColorClass.neutral.subtle.enabled,
        {
          shadow: isSelected,
        }
      )}
      onClick={selectProductGroup}
    >
      <div className="self-stretch divide-x h-28 max-h-28 inline-flex justify-start items-start overflow-hidden">
        {[0, 1].map((index) => (
          <ImageWithFallback
            key={index}
            className="h-28 w-1/2"
            src={productGroup.products[index]?.variants[0]?.photos[0]?.url}
            alt={`${productGroup.name}-image-${index + 1}`}
            border={false}
            fit="object-contain"
            srcFallback={imageIcon}
          />
        ))}
      </div>
      <div
        className={clsx(
          "self-stretch p-4 justify-start items-start gap-4",
          productsRef ? "flex flex-col" : "inline-flex"
        )}
      >
        <Typography
          className="self-stretch h-11 justify-start font-semibold line-clamp-2"
          color="neutral.boldest.enabled"
        >
          {productGroup.name}
        </Typography>
        {productsRef && (
          <BookmarkLink onClick={() => {}} label="View products" background />
        )}
      </div>
    </div>
  );
}
