import clsx from "clsx";
import { useShowSidebar } from "../../../shared/Sidebar/useShowSidebar";
import ProjectOverviewCollaborators from "./ProjectOverviewCollaborators";
import ProjectOverviewDetails from "./ProjectOverviewDetails";
import ProjectOverviewNotes from "./ProjectOverviewNotes";
import ProjectOverviewTasks from "./ProjectOverviewTasks";

export default function ProjectOverview() {
  const showSidebar = useShowSidebar();
  return (
    <div className="px-6 md:px-8">
      <div className="max-w-[75rem] m-auto grid grid-cols-4 md:grid-cols-12 gap-6 items-start">
        <div className="col-span-full grid gap-4 mb-8 md:col-span-4">
          <ProjectOverviewDetails />
          {showSidebar && <ProjectOverviewCollaborators />}
        </div>
        <div
          className={clsx("col-span-full md:col-span-8 grid gap-4 mb-8", {
            "order-first": showSidebar,
          })}
        >
          <ProjectOverviewTasks />
          <ProjectOverviewNotes />
        </div>
      </div>
    </div>
  );
}
