import type { Getter, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { ApiService } from "../generated";
import { projectContextState, savedSuppliersAtom } from "../jotai/projects";

export default function useRefreshSavedSuppliers() {
  const refreshSavedSuppliers = useCallback(
    async (get: Getter, set: Setter) => {
      const projectContext = get(projectContextState);
      if (!projectContext?.id) return;
      const suppliers = await ApiService.apiV1ProjectsSuppliersList(
        projectContext.id
      );
      set(savedSuppliersAtom, suppliers);
    },
    []
  );

  return useAtomCallback(refreshSavedSuppliers);
}
