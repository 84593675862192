import clsx from "clsx";
import {
  CardContainer,
  CardContainerVariant,
  Link,
  Typography,
} from "../../../library";
import Tag from "../../../shared/Tag";
import useQuickLinks from "./useQuickLinks";

export default function QuickLinks({ className }: { className?: string }) {
  const links = useQuickLinks();

  return (
    <CardContainer
      className={clsx("p-6 grid gap-4", className)}
      variant={CardContainerVariant.PRIMARY}
    >
      <Typography
        variant="headline"
        size="xs"
        color="brand.boldest.enabled"
        emphasis
      >
        Quick links
      </Typography>
      {links.map(({ label, tag, className, ...rest }, ix) => (
        <Link
          // biome-ignore lint/suspicious/noArrayIndexKey: This list is static, so no need to have unique keys.
          key={ix}
          size="sm"
          color="neutral.boldest.enabled"
          className={clsx("hover:underline", className)}
          emphasis={false}
          underline={false}
          {...rest}
        >
          {label}
          {tag && (
            <Tag className="ml-2" variant={tag.variant}>
              {tag.label}
            </Tag>
          )}
        </Link>
      ))}
    </CardContainer>
  );
}
