import { useAtomValue, useSetAtom } from "jotai";
import useRequestID from "../../../hooks/useRequestID";
import {
  deleteProjectPurchaseOptionSupplierAtom,
  projectContextState,
  projectPurchaseOptionSuppliersLoadable,
} from "../../../jotai/projects";
import { BookmarkLink, Button, Typography } from "../../../library";
import { goToURL } from "../../../utils";
import { borderColorClass } from "../../../utils/colors";
import { pageNavigationSourceTypes } from "../../../utils/enums";
import { getMessageFromProjectUrl } from "../../../utils/format";
import {
  trackClickReviewOptionChecklist,
  trackMessageSupplierFromPurchaseRequest,
} from "../../../utils/tracking";
import { SavedSuppliersList } from "../../Pill/SavedSuppliersList";

export default function SelectedOptionContent() {
  const requestID = useRequestID();
  const projectContext = useAtomValue(projectContextState);
  const deleteSupplierFromPurchaseOption = useSetAtom(
    deleteProjectPurchaseOptionSupplierAtom
  );
  const suppliersLoadable = useAtomValue(
    projectPurchaseOptionSuppliersLoadable
  );

  if (!projectContext?.purchaseRequest?.selectedOption) return null;

  const { id, selectedOption } = projectContext.purchaseRequest;
  const suppliers =
    suppliersLoadable.state === "hasData" ? suppliersLoadable.data : [];

  const onClickReviewChecklist = () => {
    trackClickReviewOptionChecklist({
      requestID,
      projectId: projectContext.id,
      purchaseRequestId: id,
      purchaseOptionId: selectedOption.id,
    });
  };

  if (!selectedOption.searchType) {
    return (
      <BookmarkLink
        label="Review checklist"
        href="#checklist"
        onClick={onClickReviewChecklist}
      />
    );
  }

  return (
    <>
      <BookmarkLink
        label="Review checklist"
        href="#checklist"
        onClick={onClickReviewChecklist}
      />
      <hr className={borderColorClass.neutral.subtle.enabled} />
      <Typography
        variant="headline"
        size="xs"
        emphasis
        color="neutral.boldest.enabled"
      >
        Saved suppliers ({suppliers.length})
      </Typography>
      <SavedSuppliersList
        suppliers={suppliers}
        pageNavigationSource={pageNavigationSourceTypes.REQUEST_SEARCH}
        onDelete={(supplierId) =>
          deleteSupplierFromPurchaseOption({ id: supplierId })
        }
      />
      <Button
        size={Button.sizes.LARGE}
        className="analytics-project-get-quotes w-fit"
        onClick={() => {
          trackMessageSupplierFromPurchaseRequest({
            projectId: projectContext.id,
            requestID,
            purchaseRequestId: id,
            purchaseOptionId: selectedOption.id,
            supplierIds: suppliers.map(({ supplier }) => supplier.id),
          });
          goToURL(
            getMessageFromProjectUrl({
              projectId: projectContext.id,
              requestID,
            })
          );
        }}
        disabled={!suppliers.length}
        dataTestId="project-email-suppliers"
      >
        Email suppliers
      </Button>
    </>
  );
}
