import { useAtomValue } from "jotai";
import { ItemTypeEnum } from "../generated";
import { projectConstantsAtom, projectContextState } from "../jotai/projects";
import { LoginWallTriggers } from "../utils/enums";

interface SaveToProjectButtonProps {
  triggerType: LoginWallTriggers;
  savedCopy: string;
  saveCtaCopy: string;
  viewSavedCtaCopy: string;
}

const BUTTON_PROPS: Record<ItemTypeEnum, SaveToProjectButtonProps> = {
  [ItemTypeEnum.CONTRACT]: {
    triggerType: LoginWallTriggers.SAVE_CONTRACT_TO_PROJECT,
    savedCopy: "Saved contract",
    saveCtaCopy: "Save contract",
    viewSavedCtaCopy: "Contract saved! View your saved contract",
  },
  [ItemTypeEnum.SUPPLIER]: {
    triggerType: LoginWallTriggers.SAVE_SUPPLIER_TO_PROJECT,
    savedCopy: "Saved supplier",
    saveCtaCopy: "Save supplier",
    viewSavedCtaCopy:
      "Supplier profile saved! View your saved supplier profile",
  },
};

export function useSaveToProjectButtonProps(): Record<
  ItemTypeEnum,
  SaveToProjectButtonProps
> {
  const projectContext = useAtomValue(projectContextState);
  const { name } = useAtomValue(projectConstantsAtom);

  if (!projectContext) {
    return BUTTON_PROPS;
  }

  const copyProps: Partial<SaveToProjectButtonProps> = {
    savedCopy: `Saved to ${name}`,
    saveCtaCopy: `Save to ${name}`,
    viewSavedCtaCopy: `Saved to ${name}!`,
  };

  return {
    [ItemTypeEnum.CONTRACT]: {
      ...BUTTON_PROPS[ItemTypeEnum.CONTRACT],
      ...copyProps,
    },
    [ItemTypeEnum.SUPPLIER]: {
      ...BUTTON_PROPS[ItemTypeEnum.SUPPLIER],
      ...copyProps,
    },
  };
}
