import clsx from "clsx";
import AgreementIcon from "../../../img/icons/agreement.svg";
import CheckIcon from "../../../img/icons/check.svg";
import connector from "../../../img/icons/connector.svg";
import FindIcon from "../../../img/icons/find.svg";
import { Typography } from "../../library";
import { elevationClass } from "../../utils/designTokens";
import { isFeatureEnabled } from "../../utils/features";

export function NoPurchaseOptionSelected() {
  return (
    <div
      className={clsx(
        "border rounded-8 grid gap-6 py-6",
        elevationClass["elevation-1"]
      )}
    >
      <Typography
        variant="headline"
        className="font-homeHeader px-6"
        dataTestId="no-purchase-option-selected"
      >
        Choose a purchase method
      </Typography>
      <Typography size="sm" className="px-6">
        We checked your organization's guidelines for compliant ways to
        purchase. Choose one on the{" "}
        {isFeatureEnabled("sidebarNav") ? "right" : "left"} to explore available
        suppliers, review your checklist, and get quotes.
      </Typography>
      <div className="relative grid-cols-3 px-6 z-0 h-44 hidden md:grid">
        <img
          src={connector}
          className="absolute top-0 left-0 w-full opacity-40"
        />
        <img
          src={FindIcon}
          alt="a person with a magnifying glass"
          className="h-30 z-1 mx-auto mt-8"
        />
        <img
          src={CheckIcon}
          alt="people looking at a checklist"
          className="h-30 z-1 mx-auto mt-6"
        />
        <img
          src={AgreementIcon}
          alt="a handshake"
          className="h-30 z-1 mx-auto mt-9"
        />
      </div>
    </div>
  );
}
