import _isEqual from "lodash/isEqual";
import _pick from "lodash/pick";
import { v4 as uuidv4 } from "uuid";

import type {
  ContractSearchParams,
  SearchOptions,
  SearchParams,
} from "../components/ContractSearch/types";
import {
  formatSearchPageParams,
  validateSearchParams,
} from "../components/ContractSearch/utils";
import {
  DEFAULT_SEARCH_RESPONSE,
  contractSearchAnalyticsParamsState,
  contractSearchIsLoadingState,
  contractSearchParamsState,
  contractSearchResponseDataState,
  disallowedSupplierSearchQueryState,
  redirectSearchResponseDataState,
  searchResultTypeState,
  searchTypeAtom,
  supplierSearchResponseDataState,
  topSupplierContractCardState,
  updateSearchResponseDataState,
} from "../jotai/search";
import { getParam, hasWindow } from "../utils";
import { MAX_RESULTS } from "../utils/constants";
import { SearchActions, type SearchFilter } from "../utils/enums";
import { handleError as handleGeneratedError } from "../utils/generatedApi";

import { captureException } from "@sentry/browser";
import type { Getter, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { ApiService, type Project, SearchTypeEnum } from "../generated";
import { projectContextState } from "../jotai/projects";
import { getProBoost } from "./search/utils";
import useTrackContractSearch from "./useTrackContractSearch";

export function shouldUpdateRequestID(
  oldSearchParams: SearchParams,
  newSearchParams: SearchParams
) {
  // Only update the request ID if we have meaningfully changed the
  // params. The only fields that count this as a new request for analytics
  // are query, zip, and filters.
  const paramsToInclude = ["query", "zip", "filters", "collapseBySupplier"];

  const oldComparedParams = _pick(oldSearchParams, paramsToInclude);
  const newComparedParams = _pick(newSearchParams, paramsToInclude);

  if (!oldSearchParams || _isEqual(oldComparedParams, newComparedParams)) {
    return false;
  }
  return true;
}

function withProjectSearchOverrides(
  params: ContractSearchParams,
  project: Project | null
): ContractSearchParams {
  if (!project || !project.purchaseRequest?.selectedOption) return params;
  const option = project.purchaseRequest.selectedOption;
  if (!option.searchType) return params;

  const filters = (option.searchFilters || []) as SearchFilter[];
  return {
    ...params,
    collapseBySupplier: true,
    filters,
    searchType:
      (option.searchType as SearchTypeEnum) || SearchTypeEnum.SUPPLIER,
  };
}

export interface SearchContractsAndSuppliersArgs {
  requestID: string;
  set: Setter;
  query?: string;
  filters?: string[];
  zip?: string;
  embedSourceEntityId?: string;
  parentRequestID?: string;
  originalAmbiguousQuery?: Maybe<string>;
  selectedDisambiguationOption?: Maybe<string>;
  skipSupplierSearch?: boolean;
  supplierIds?: number[];
  projectId: Maybe<string>;
  searchType: SearchTypeEnum;
  // Deprecated in favor of searchType
  collapseBySupplier?: boolean;
}

const searchContractsAndSuppliers = async ({
  requestID,
  set,
  query,
  filters,
  zip,
  embedSourceEntityId,
  collapseBySupplier,
  parentRequestID,
  originalAmbiguousQuery,
  selectedDisambiguationOption,
  skipSupplierSearch,
  searchType,
  supplierIds,
  projectId,
}: SearchContractsAndSuppliersArgs) => {
  set(contractSearchIsLoadingState, true);
  set(contractSearchResponseDataState, null);
  set(supplierSearchResponseDataState, null);
  set(redirectSearchResponseDataState, null);
  set(topSupplierContractCardState, null);
  set(searchResultTypeState, collapseBySupplier ? "supplier" : "contract");
  set(searchTypeAtom, searchType);
  try {
    const response = await ApiService.apiV1ContractSearchCreate({
      query: query || "",
      filters,
      zip,
      embedSourceEntityId,
      // When filtering clientside, retrieve results that includes all
      // strong matches in one go to offer the largest bucket of filterable
      // results.
      numResultsPerPage: MAX_RESULTS,
      page: 0,
      requestID,
      parentRequestID,
      proBoost: getProBoost("proBoost"),
      evProBoost: getProBoost("evProBoost"),
      ocProBoost: getProBoost("ocProBoost"),
      targetProBoost: getProBoost("targetProBoost"),
      productBoost: getProductBoost(),
      rankStrategy: getParam("rankStrategy"),
      collapseBySupplier,
      originalAmbiguousQuery,
      selectedDisambiguationOption,
      skipSupplierSearch,
      searchType,
      supplierIds,
      originatingProjectId: projectId,
    });
    set(updateSearchResponseDataState, response, { skipSupplierSearch });
    return response;
  } catch (err) {
    handleGeneratedError(err);
    set(updateSearchResponseDataState, null, { skipSupplierSearch });
    return null;
  }
};

function getProductBoost() {
  const rawProductBoost = hasWindow() && getParam("productBoost");
  if (!rawProductBoost) {
    return null;
  }
  const parsedProductBoost = Number.parseInt(rawProductBoost);
  if (Number.isNaN(parsedProductBoost)) {
    return null;
  }
  return parsedProductBoost;
}

export default function useSearchContractWithParams() {
  const trackContractSearch = useTrackContractSearch();

  const search = useAtomCallback(
    useCallback(
      async (
        get: Getter,
        set: Setter,
        { newParams = {}, action = SearchActions.SEARCH }: SearchOptions
      ) => {
        const searchParams = get(contractSearchParamsState);
        const analyticsParams = get(contractSearchAnalyticsParamsState);
        const searchResultType = get(searchResultTypeState);
        const projectContext = get(projectContextState);

        const params = formatSearchPageParams(searchParams);
        const combinedParams = {
          ...withProjectSearchOverrides(
            {
              ...params,
              searchType:
                searchResultType === "supplier"
                  ? SearchTypeEnum.SUPPLIER
                  : SearchTypeEnum.CONTRACT_SOLICITATION,
              collapseBySupplier: searchResultType === "supplier",
              supplierIds: projectContext?.purchaseRequest?.supplierIds,
            },
            projectContext
          ),
          ...newParams,
        };

        const validation = validateSearchParams(combinedParams);
        if (!validation.valid) {
          set(contractSearchResponseDataState, {
            ...DEFAULT_SEARCH_RESPONSE,
            errorMessage: validation.errorMessage,
          });
          return;
        }
        const {
          query,
          zip,
          page,
          filters,
          embedSourceEntityId,
          searchSource,
          collapseBySupplier,
          originalAmbiguousQuery,
          selectedDisambiguationOption,
          searchType,
          supplierIds,
        } = combinedParams;

        // If we are searching contracts only, we don't want to update the query state -
        // this supports the supplier redirect experience.
        const updatedSearchQuery =
          action === SearchActions.SEARCH_CONTRACTS_ONLY
            ? searchParams.query
            : query || "";

        // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAM IN THE URL.
        // PLEASE COORDINATE IF THESE CHANGE.
        const updatedParams: SearchParams = {
          query: updatedSearchQuery,
          zip: zip || "",
          page: page?.toString() || "",
          filters: filters?.join(";") || "",
        };

        const disallowedSupplierSearchQuery = get(
          disallowedSupplierSearchQueryState
        );

        // For supplier redirects, we want to skip updating the supplier data when:
        const skipSupplierSearch =
          // - the user clicked "search for <supplier name> contracts" and wants a contract-only search
          action === SearchActions.SEARCH_CONTRACTS_ONLY ||
          // - the user is applying filters on a contract-only search
          query === disallowedSupplierSearchQuery;

        // Once we have a non-skipped search, we should reset all the redirect state.
        // This state is managed and used in useHideSupplierSearch.ts.
        if (!skipSupplierSearch) {
          set(disallowedSupplierSearchQueryState, "");
        }

        // Update search params local state, which binds to URL param changes.
        if (!_isEqual(searchParams, updatedParams)) {
          set(contractSearchParamsState, updatedParams);
        }
        const projectId = get(projectContextState)?.id;
        const requestID = shouldUpdateRequestID(searchParams, {
          ...updatedParams,
          collapseBySupplier,
        })
          ? uuidv4()
          : analyticsParams.requestID || uuidv4();

        // If we change the request id, track this as a new search.
        if (requestID !== analyticsParams.requestID) {
          set(contractSearchAnalyticsParamsState, {
            requestID,
            searchSource: searchSource || "",
          });
        }

        const multiResponse = await searchContractsAndSuppliers({
          requestID,
          set,
          query,
          filters,
          zip,
          embedSourceEntityId,
          collapseBySupplier,
          parentRequestID: undefined,
          originalAmbiguousQuery,
          selectedDisambiguationOption,
          skipSupplierSearch,
          searchType,
          supplierIds,
          projectId,
        });

        // Track the original search.
        if (multiResponse) {
          const numSupplierHits =
            multiResponse.supplierData?.supplierData?.suppliers?.length || 0;
          trackContractSearch(get, set, {
            data: multiResponse,
            action,
            numSupplierHits,
            collapseBySupplier,
          });
        }

        // After all the redirects have been handled, update the search params state
        if (multiResponse) {
          let params: SearchParams;
          params = {
            query: updatedSearchQuery,
            zip: multiResponse.params?.zip || "",
            page: multiResponse.params?.page?.toString() || "",
            filters: multiResponse.params?.filters?.join(";") || "",
          };
          if (multiResponse.params?.embedSourceEntityId) {
            params = {
              ...params,
              embedSourceEntityId: multiResponse.params.embedSourceEntityId,
            };
          }
          set(contractSearchParamsState, params);

          if (!multiResponse.params?.requestId) {
            // Track if the contract search response does not have a request ID - this is a problem
            captureException(
              new Error("Contract search response does not have a request ID"),
              {
                extra: { multiResponse },
              }
            );
          }

          set(contractSearchAnalyticsParamsState, {
            // We want clicks to be associated with the redirected search if we have it.
            requestID:
              multiResponse.params?.childRequestId ||
              multiResponse.params?.requestId ||
              requestID,
            searchSource: searchSource || "",
          });
        }
      },
      [trackContractSearch]
    )
  );

  return search;
}
