import { useAtomValue } from "jotai";
import { projectContextState } from "../../../jotai/projects";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../../library";
import { useShowInviteModal } from "../../../modals/ProjectModals/ProjectInviteModal";
import CollaboratorAvatars from "../../Project/CollaboratorAvatars";

export default function ProjectOverviewCollaborators() {
  const showInviteModal = useShowInviteModal();
  const projectContext = useAtomValue(projectContextState);
  const hasEditAccess = projectContext?.userRole;
  return (
    <CardContainer
      className="p-6 gap-4"
      variant={CardContainerVariant.SECONDARY}
    >
      <div className="flex flex-row items-center justify-between">
        <Typography variant="headline" size="sm" emphasis>
          Collaborators
        </Typography>
        {hasEditAccess && (
          <Button
            theme={ButtonThemes.TERTIARY_DARK}
            size={ButtonSizes.SMALL}
            className="flex-none w-fit"
            onClick={() => showInviteModal({ projectId: projectContext.id })}
            dataTestId="project-invite"
          >
            Invite
          </Button>
        )}
      </div>

      <div className="flex flex-col items-start gap-4">
        {projectContext?.collaborators.length === 1 && (
          <Typography size="sm" color="neutral.bolder.enabled">
            No collaborators yet
          </Typography>
        )}
        <CollaboratorAvatars
          collaborators={projectContext?.collaborators ?? []}
          className="ml-2"
        />
      </div>
    </CardContainer>
  );
}
