import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import MenuRounded from "@mui/icons-material/MenuRounded";
import clsx from "clsx";
import { useEffect, useState } from "react";

import Sidebar from ".";
import pavilionLogo from "../../../img/logos/pavilion-logo.svg";
import { Badge, Button, Link } from "../../library";
import { COMPANY_NAME } from "../../utils/constants";
import { MAIN_HEADER_CONTAINER_CLASS } from "../Header/constants";

export default function CollapsibleSidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Scroll-lock when sidebar is open
  useEffect(() => {
    if (sidebarOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [sidebarOpen]);
  return (
    <>
      <header
        className={clsx(MAIN_HEADER_CONTAINER_CLASS, "z-4 lg:hidden px-6")}
      >
        <div className="w-[120px]">
          <Badge
            as={Link}
            size="lg"
            Icon={MenuRounded}
            className="analytics-header-sidebar-toggle w-fit cursor-pointer"
            onClick={() => setSidebarOpen((prev) => !prev)}
          />
        </div>
        <div className="flex flex-none flex-grow justify-center">
          <Link
            className="analytics-logo-link"
            href="/welcome"
            target="_parent"
            newWindow={false}
          >
            <img
              className="h-7"
              src={pavilionLogo}
              alt={`${COMPANY_NAME} logo`}
            />
          </Link>
        </div>
        <Button
          as={Link}
          theme={Button.themes.PRIMARY_DARK}
          size={Button.sizes.SMALL}
          className="analytics-header-new-request w-[120px] whitespace-nowrap"
          dataTestId="header-new-purchase"
          linkProps={{
            href: "/intake",
            underline: false,
            newWindow: false,
          }}
        >
          New purchase
        </Button>
      </header>
      <Dialog
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        className="relative z-4 lg:hidden"
      >
        <DialogBackdrop
          transition
          className="fixed top-20 min-h-screen w-full bg-cp-transparent-dark-300 transition duration-300 ease-out data-[closed]:opacity-0"
        />
        <div className="fixed top-20">
          <DialogPanel
            transition
            className="transition duration-300 ease-out data-[closed]:-translate-x-full data-[closed]:opacity-0"
          >
            <Sidebar />
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
