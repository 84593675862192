import clsx from "clsx";
import { BookmarkLink, Typography } from "../../library";
import { usePurchasingGetHelpModal } from "../../modals/PurchasingGetHelpModal";
import { elevationClass } from "../../utils/designTokens";
import { SidebarLinkType } from "../../utils/enums";
import { trackSidebarLinkClick } from "../../utils/tracking";

export default function SidebarHelpCard() {
  const showGetHelpModal = usePurchasingGetHelpModal();

  return (
    <div
      className={clsx(
        "flex flex-col p-[10px] bg-white border rounded-4 box-content mx-2",
        elevationClass["elevation-1"]
      )}
    >
      <Typography variant="headline" size="sm" className="font-homeHeader px-1">
        Need help?
      </Typography>
      <Typography
        size="sm"
        color="neutral.bolder.enabled"
        className="mt-2 mb-4 px-1"
      >
        Email us and we'll respond quickly—keeping your purchase moving forward.
      </Typography>
      <BookmarkLink
        background
        onClick={() => {
          trackSidebarLinkClick({ sidebarLinkType: SidebarLinkType.GET_HELP });
          showGetHelpModal();
        }}
        label="Get support"
      />
    </div>
  );
}
