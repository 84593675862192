import { captureException } from "@sentry/browser";
import _pick from "lodash/pick";
import _pickBy from "lodash/pickBy";
import Smartlook from "smartlook-client";
import * as yup from "yup";

import type { SupplierAgreement } from "../components/WelcomePage/types";
import {
  ApiService,
  ApprovalStatusEnum,
  type BuyerProfile,
  type BuyerProfileTypeEnum,
  type GovernmentAgency,
  type Project,
  type StateCodeEnum,
  type SupplierProfile,
  type SupplierProfileTypeEnum,
  type User,
  type UserState,
  type UserSupplier,
} from "../generated";
import { userTypeTabData } from "../modals/SignupSteps/constants";
import type {
  BuyerProfileDetails,
  SupplierProfilePasswordDetails,
} from "../shared/types";
import {
  activeAgreementsOnLoad,
  browserLocalStorage,
  browserSessionStorage,
  getCSRFToken,
  getDatasetValue,
  getParam,
  hasAuthenticationOnLoad,
  onPostSocialAuthOnLoad,
  profileTypeOnLoad,
} from "../utils";
import { postLogout } from "../utils/api";
import {
  BONFIRE_USER_DETAILS_COOKIE,
  IONWAVE_USER_DETAILS_COOKIE,
  SOVRA_USER_DETAILS_COOKIE,
  SUPPLIER_ENROLLMENT_STATUS,
} from "../utils/constants";
import {
  LoginType,
  ProfileType,
  type ProfileTypes,
  type loginSignupAccountTypes,
} from "../utils/enums";
import { handleError as handleGeneratedError } from "../utils/generatedApi";
import {
  addHeapUserProperties,
  addUserProperties,
  changeHeapEventLoginStatus,
  resetIdentity,
  trackFreshMagicLinkLogin,
  trackIdentity,
  trackLogout,
  trackZipFromIpFail,
} from "../utils/tracking";

import { type Getter, type Setter, atom } from "jotai";
import { atomWithStorage, useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { createCookieStorage } from "./util";

const isAuthenticatedState = atom(hasAuthenticationOnLoad());

export const profileTypeState = atom<ProfileTypes>(profileTypeOnLoad());

const onPostSocialAuthState = atom(onPostSocialAuthOnLoad());

/**
 *
 * @param CallbackInterface
 * @return (...args Array) => Promise<void>
 */
// TODO: should logoutCallback be an atom effect on isAuthenticatedState?
//  what local values to we expect for analytics after user logout?
function logoutCallback() {
  return async (get: Getter, set: Setter) => {
    const form = new FormData();
    form.append("csrfmiddlewaretoken", getCSRFToken() || "");
    await postLogout(form);
    const userType = get(userTypeSignupState);
    const socialAccountProvider = get(userSocialAccountProviderState);
    trackLogout({
      accountType: userType,
      // If there is no social account, pass pavilion loginType
      loginType: (socialAccountProvider as LoginType) || LoginType.PAVILION,
    });
    set(isAuthenticatedState, false);
    resetIdentity();
    browserLocalStorage?.clear();
    browserSessionStorage?.clear();
    window.location.href = "/";
  };
}

type DatasetContext = {
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  govAffiliationName?: Maybe<string>;
  buyerProfileType?: Maybe<BuyerProfileTypeEnum>;
  governmentAgency?: Maybe<
    Pick<GovernmentAgency, "id" | "stateCode" | "purchasingGuide">
  >;
  supplier?: Pick<UserSupplier, "displayName">;
  project?: Project;
  intakeForm?: boolean;
  requireIntakeForm?: boolean;
};
export const datasetContext: DatasetContext = JSON.parse(
  getDatasetValue("context") || '{ "project": {} }'
);

const governmentAffiliationDisplayNameLocalCookie =
  createCookieStorage<string>();
const governmentAffiliationDisplayNameLocalState = atomWithStorage(
  "govAffiliationDisplayName",
  datasetContext.govAffiliationName || "",
  governmentAffiliationDisplayNameLocalCookie,
  { getOnInit: true }
);

export const governmentAgencyIdState = atom<Maybe<string>>(
  datasetContext?.governmentAgency?.id || null
);

export const governmentAgencyState = atom<Maybe<GovernmentAgency>>(
  (datasetContext.governmentAgency || {}) as GovernmentAgency
);

export const buyerProfileTypeState = atom<Maybe<BuyerProfileTypeEnum>>(
  (datasetContext.buyerProfileType || "") as BuyerProfileTypeEnum
);

export const intakeFormEnabledState = atom<boolean>(
  datasetContext.intakeForm || false
);

export const requireIntakeFormEnabledState = atom<boolean>(
  datasetContext.requireIntakeForm || false
);

const buyerProfileDetailsState = atom<BuyerProfileDetails>({
  verified: false,
});

const buyerProfileState = atom(
  (get): BuyerProfile => {
    const governmentAgency = get(governmentAgencyState);
    return {
      governmentAffiliationDisplayName: get(
        governmentAffiliationDisplayNameLocalState
      ),
      governmentAgency: governmentAgency || null,
      governmentAgencyId: governmentAgency?.id || null,
      buyerProfileType: get(buyerProfileTypeState),
      ...get(buyerProfileDetailsState),
    };
  },
  (_get, set, value: BuyerProfile) => {
    const {
      governmentAffiliationDisplayName,
      governmentAgency,
      buyerProfileType,
      ...rest
    } = value;
    set(
      governmentAffiliationDisplayNameLocalState,
      governmentAffiliationDisplayName || ""
    );
    set(governmentAgencyIdState, governmentAgency?.id);
    set(governmentAgencyState, governmentAgency);
    set(buyerProfileTypeState, buyerProfileType);
    set(buyerProfileDetailsState, rest);
  }
);

export const supplierProfileTypeState = atom<Maybe<SupplierProfileTypeEnum>>(
  "" as SupplierProfileTypeEnum
);

const supplierProfilePasswordState = atom<SupplierProfilePasswordDetails>({
  needPasswordChange: false,
});

export const supplierProfileState = atom(
  (get) => {
    return {
      supplierProfileType: get(supplierProfileTypeState),
      ...get(supplierProfilePasswordState),
    };
  },
  (_get, set, value: SupplierProfile) => {
    const { supplierProfileType, needPasswordChange } = value;
    set(supplierProfilePasswordState, {
      needPasswordChange: needPasswordChange || false,
    });
    set(supplierProfileTypeState, supplierProfileType);
  }
);

export const supplierExpiredAgreementsState = atom<SupplierAgreement[]>([]);

export const defaultSupplierDetails = {
  id: -1,
  about: null,
  addressString: "",
  approvalStatus: ApprovalStatusEnum.PENDING,
  displayName: null,
  manualContacts: [],
  manualServiceAreaLocal: null,
  manualServiceAreaNational: null,
  manualServiceAreaState: null,
  manualAgencyRelationships: [],
  website: null,
  enrollmentStatus: SUPPLIER_ENROLLMENT_STATUS.UNTARGETED,
  targetedAgreementId: null,
  contractsNeedExpirationReview: false,
  contractsNeedDocReview: false,
  confirmedAllContracts: false,
  hasExclusions: false,
  confirmedNoExclusions: false,
  defaultContactId: -1,
  hasOptimizedAllPriorityContracts: false,
  hasManualContactWithVerifiedPhone: false,
  hasSubmittedManualServiceArea: false,
  hasSignedOcAgreement: false,
  hasSignedFullAgreement: false,
  hasSignedAnnualViewsAgreement: false,
  hasConfirmedContact: false,
  logoUrl: null,
  handle: null,
  activeAgreements: [],
};

export const supplierDetailsState = atom<UserSupplier>({
  ...defaultSupplierDetails,
  ...(datasetContext.supplier || {}),
});

const supplierAccountHandleState = atom("");

export const supplierActiveAgreementsState = atom<string[]>(
  activeAgreementsOnLoad()
);

export const supplierState = atom(
  (get) => {
    return {
      ...get(supplierDetailsState),
      handle: get(supplierAccountHandleState),
      activeAgreements: get(supplierActiveAgreementsState),
    };
  },
  (
    get,
    set,
    update: UserSupplier | ((prevState: UserSupplier) => UserSupplier)
  ) => {
    const updatedState =
      typeof update === "function" ? update(get(supplierState)) : { ...update };
    const { handle, activeAgreements } = updatedState;
    set(supplierAccountHandleState, handle || "");
    set(supplierActiveAgreementsState, activeAgreements || []);
    set(supplierDetailsState, updatedState);
  }
);

const userEmailCookieStorage = createCookieStorage<string>();
const userEmailState = atomWithStorage(
  "userEmail",
  "",
  userEmailCookieStorage,
  { getOnInit: true }
);

export const userPasswordAtom = atom<string>("");

const userEmailVerifiedState = atom(false);

const userSocialAccountProviderState = atom("");

const userAdminState = atom(false);

const defaultUserBonfireDetailsState = { userId: "", email: "" };
const userBonfireCookieStorage = createCookieStorage<{
  userId: string;
  email: string;
}>({
  deserialize: (s) => {
    return JSON.parse(s);
  },
  serialize: (o) => {
    return JSON.stringify(o);
  },
  defaultValue: JSON.stringify(defaultUserBonfireDetailsState),
});
const userBonfireDetailsState = atomWithStorage<{
  userId: string;
  email: string;
}>(
  BONFIRE_USER_DETAILS_COOKIE,
  defaultUserBonfireDetailsState,
  userBonfireCookieStorage,
  { getOnInit: true }
);

const defaultUserIonwaveDetailsState = { email: "" };
const userIonwaveDetailsCookie = createCookieStorage<{
  email: string;
}>({
  deserialize: (s) => {
    return JSON.parse(s);
  },
  serialize: (o) => {
    return JSON.stringify(o);
  },
  defaultValue: JSON.stringify(defaultUserIonwaveDetailsState),
});
const userIonwaveDetailsState = atomWithStorage<{
  email: string;
}>(
  IONWAVE_USER_DETAILS_COOKIE,
  defaultUserIonwaveDetailsState,
  userIonwaveDetailsCookie,
  { getOnInit: true }
);

const defaultUserSovraDetailsState = { email: "" };
const userSovraDetailsCookie = createCookieStorage<{
  email: string;
}>({
  deserialize: (s) => {
    return JSON.parse(s);
  },
  serialize: (o) => {
    return JSON.stringify(o);
  },
  defaultValue: JSON.stringify(defaultUserSovraDetailsState),
});
const userSovraDetailsState = atomWithStorage<{
  email: string;
}>(
  SOVRA_USER_DETAILS_COOKIE,
  defaultUserSovraDetailsState,
  userSovraDetailsCookie,
  { getOnInit: true }
);

// TODO: this atom is used for sign up, and we should keep this as potentially undefined
export interface IUserDetailsState {
  id: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  isSupplier?: boolean;
}

export const userLocalState = atom<IUserDetailsState>({
  id: -1,
  firstName: datasetContext.firstName || "",
  lastName: datasetContext.lastName || "",
});

const userDetailsState = atom<
  IUserDetailsState,
  [IUserDetailsState | ((prev: IUserDetailsState) => IUserDetailsState)],
  void
>(
  (get) => {
    const bonfireDetail = get(userBonfireDetailsState);
    return {
      email: get(userEmailState) || bonfireDetail.email,
      ...get(userLocalState),
    };
  },
  (get, set, update) => {
    const updatedState =
      typeof update === "function"
        ? update(get(userDetailsState))
        : { ...update };

    const { email, ...rest } = updatedState;
    set(userEmailState, email || "");
    if (updatedState) set(userLocalState, rest);
  }
);

const userStateState = atom<UserState>({
  id: 0,
  diversityCertifications: {},
  location: { zip: "", city: "", state: "", latLng: [] },
  showIntentSurvey: false,
});

export const userStateCodeState = atom<StateCodeEnum | null>((get) => {
  const { location } = get(userStateState);
  const { stateCode } = get(governmentAgencyState) || {};
  return ((location?.state || stateCode) as StateCodeEnum) || null;
});

const userInitializedState = atom(false);

const fetchUserState = atom(async (get) => {
  const isAuthenticated = get(isAuthenticatedState);

  if (!isAuthenticated) return {} as User;
  try {
    const data = await ApiService.apiV1UsersRetrieve("me");
    return data;
  } catch (e) {
    handleGeneratedError(e);
    captureException(e);
    return {} as User;
  }
});

// When changing this selector, not that sub-selectors should also be updated
// to handle modified keys.
const userState = atom(
  (get) => {
    return {
      ...get(userDetailsState),
      emailVerified: get(userEmailVerifiedState),
      isAdmin: get(userAdminState),
      socialAccountProvider: get(userSocialAccountProviderState),
      buyerProfile: get(buyerProfileState),
      userState: get(userStateState),
      supplier: get(supplierState),
      supplierProfile: get(supplierProfileState),
    };
  },
  (_get, set, value: User) => {
    userSchema.validateSync(value);

    set(
      userDetailsState,
      _pick(value, ["id", "firstName", "lastName", "email"])
    );
    set(userEmailVerifiedState, value?.emailVerified || false);
    set(userSocialAccountProviderState, value?.socialAccountProvider || "");
    set(userAdminState, value?.isAdmin || false);
    set(
      userStateState,
      value?.userState || {
        id: 0,
        diversityCertifications: {},
        location: { zip: "", city: "", state: "", latLng: [] },
        showIntentSurvey: false,
      }
    );
    set(buyerProfileState, value?.buyerProfile || ({} as BuyerProfile));
    set(
      supplierState,
      value?.supplier || {
        ...defaultSupplierDetails,
        handle: null,
        activeAgreements: [],
      }
    );
    set(
      supplierProfileState,
      value?.supplierProfile || ({} as SupplierProfile)
    );

    updateHeapUser(value);
    updateSmartlookUser(value);
    return value;
  }
);

const governmentAgencySchema = yup.object({
  id: yup.string(),
  agencyType: yup.string(),
  agencyTypeLabel: yup.string(),
  zipCode: yup.string().nullable(),
  approvedSources: yup.array().of(yup.string()).nullable(),
  approvedStates: yup.array().of(yup.string()).nullable(),
});

const buyerProfileSchema = yup.object({
  governmentAffiliationDisplayName: yup.string().nullable(),
  governmentAgency: governmentAgencySchema.nullable(),
  verified: yup.boolean(),
});

const supplierSchema = yup.object({
  handle: yup.string().nullable(),
  displayName: yup.string().nullable(),
  approvalStatus: yup.string().oneOf(Object.values(ApprovalStatusEnum)),
});

const userTypeSignupState = atomWithStorage<loginSignupAccountTypes>(
  "userTypeSignupState",
  userTypeTabData.buyer.type,
  undefined,
  { getOnInit: true }
);

const userStateSchema = yup.object({
  ignoreAgencyLocation: yup.boolean(),
  role: yup.string().nullable(),
  buyerRole: yup.string().nullable(),
  supplierRole: yup.string().nullable(),
  searchZip: yup.string().nullable(),
  showExpired: yup.boolean(),
  showGsa: yup.boolean(),
  showOnlyCoop: yup.boolean(),
  showDiversityCertifications: yup.boolean(),
  showOnlySingleAward: yup.boolean(),
  excludeUnusable: yup.boolean(),
});

const userDetailsSchema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  email: yup.string(),
});

const userSchema = userDetailsSchema.concat(
  yup.object({
    emailVerified: yup.boolean(),
    socialAccountProvider: yup.string().nullable(),
    isAdmin: yup.bool().nullable(),
    userState: userStateSchema.nullable(),
    buyerProfile: buyerProfileSchema.nullable(),
    supplier: supplierSchema.nullable(),
  })
);

export const userZipState = atom("");

// 1. Fetch user data from server via fetchUserState.
// 2. Load old user value from snapshot via userState.
// 3. Get values from URL parameters.
// 4. Set user, prioritizing server data, then user params,
//    then existing values.
// TODO: This function needs more robust testing of the above functionality.
function initializeUserCallback() {
  const initialize = useCallback(async (get: Getter, set: Setter) => {
    const profileType = get(profileTypeState);
    const {
      buyerProfile,
      userState: _userState,
      emailVerified,
      socialAccountProvider,
      isAdmin,
      ...userDetails
    } = await get(fetchUserState);
    // NOTE: IT'S ONLY APPROPRIATE TO IGNORE THESE SINCE ALL OF
    //  THESE VALUES SHOULD BE SAVED ON BACKEND FOR LOGGED-IN USERS.
    const newValue = {
      ..._pickBy({
        // biome-ignore lint/suspicious/noMisleadingCharacterClass: I think the `-` in this regex is a false positive
        email: getParam("user").replace(/[\u200B-\u200D\uFEFF]/g, ""),
        firstName: getParam("firstName"),
        lastName: getParam("lastName"),
      }),
      ...userDetails,
      buyerProfile: {
        // When searching from widgets, we automatically set the affiliation via params.
        ..._pickBy({
          governmentAffiliationDisplayName: getParam("governmentAffiliation"),
        }),
        ...buyerProfile,
      },
      userState: _userState,
      emailVerified,
      socialAccountProvider,
      isAdmin,
    };
    set(userState, newValue);

    const newProfileType =
      profileType ||
      (buyerProfile
        ? ProfileType.BUYER
        : userDetails.supplierProfile
          ? ProfileType.SUPPLIER
          : null);

    set(profileTypeState, newProfileType);

    // Set heap identity at time of user initialization
    const userId = newValue?.id;
    if (userId) {
      trackIdentity(userId.toString());
      changeHeapEventLoginStatus(true);
      if (newValue.email) trackFreshMagicLinkLogin(newValue.email);
    } else {
      changeHeapEventLoginStatus(false);
    }

    if (profileType) addUserProperties({ profileType });
  }, []);

  return useAtomCallback(initialize);
}

export function initializeUserZipCallback() {
  const initialize = useCallback(async (get: Getter, set: Setter) => {
    const userState = get(userStateState);
    const governmentAgency = get(governmentAgencyState);
    const zipParam = getParam("zip") || getParam("queryZip");
    const newZip =
      userState?.searchZip || governmentAgency?.zipCode || zipParam;
    if (newZip) {
      set(userZipState, newZip);
      return;
    }

    try {
      const ipZip = await ApiService.apiV1ZipCodeFromIpRetrieve();
      set(userZipState, ipZip);
    } catch (err) {
      handleGeneratedError(err);
      trackZipFromIpFail();
    }
  }, []);

  return useAtomCallback(initialize);
}

function updateHeapUser(user: User) {
  addHeapUserProperties(
    _pickBy({
      userId: user?.id?.toString(),
      email: user?.email,
      governmentAgencyId: user?.buyerProfile?.governmentAgency?.id,
      govAffiliationName: user?.buyerProfile?.governmentAffiliationDisplayName,
      govAffiliationState: user?.buyerProfile?.governmentAgency?.stateCode,
      role: user?.userState?.role,
      buyerRole: user?.userState?.buyerRole,
      supplierRole: user?.userState?.supplierRole,
    })
  );
}

function updateSmartlookUser(user: User) {
  // Allow recording inputs (like search bar).
  Smartlook.record({ forms: true, emails: true, numbers: true });

  if (!user?.id) return;
  const props = _pickBy({
    userId: user?.id?.toString(),
    email: user?.email,
    profileType: user?.buyerProfile
      ? ProfileType.BUYER
      : user?.supplierProfile
        ? ProfileType.SUPPLIER
        : null,
    governmentAgencyId: user?.buyerProfile?.governmentAgency?.id,
    govAffiliationName: user?.buyerProfile?.governmentAffiliationDisplayName,
    govAffiliationState: user?.buyerProfile?.governmentAgency?.stateCode,
    role: user?.userState?.role,
  }) as Record<string, string>;
  Smartlook.identify(user?.id?.toString(), props);
}

export {
  buyerProfileState,
  governmentAffiliationDisplayNameLocalState,
  initializeUserCallback,
  isAuthenticatedState,
  logoutCallback,
  onPostSocialAuthState,
  supplierAccountHandleState,
  userAdminState,
  userBonfireDetailsState,
  userDetailsSchema,
  userDetailsState,
  userEmailState,
  userEmailVerifiedState,
  userInitializedState,
  userIonwaveDetailsState,
  userSovraDetailsState,
  userSocialAccountProviderState,
  userState,
  userStateState,
  userTypeSignupState,
};
