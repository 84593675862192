import AccountBalanceOutlined from "@mui/icons-material/AccountBalanceOutlined";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import { useAtomValue } from "jotai";
import pluralize from "pluralize";

import { buyerProfileState, userState } from "../../../jotai/user";
import { buyerWelcomePageState } from "../../../jotai/welcome";
import { Badge, Typography } from "../../../library";
import { ContractIcon } from "../../../shared/CustomIcons/ContractIcon";
import { textColorClass } from "../../../utils/colors";

export default function BuyerSummary() {
  const { firstName } = useAtomValue(userState);
  const { governmentAffiliationDisplayName } = useAtomValue(buyerProfileState);
  const { numContracts, numTeamMembers } = useAtomValue(buyerWelcomePageState);

  return (
    <>
      <Typography
        variant="display"
        className="sm:text-cp-display-md text-cp-display-sm"
        color="brand.boldest.enabled"
      >
        Welcome{firstName ? `, ${firstName}` : ""}
      </Typography>
      <div className="flex flex-wrap gap-y-1 gap-x-2 lg:mb-4">
        <Badge
          size="sm-md"
          Icon={AccountBalanceOutlined}
          className={textColorClass.neutral.bolder.enabled}
        >
          <Typography size="sm" color="neutral.bolder.enabled">
            {governmentAffiliationDisplayName}
          </Typography>
        </Badge>
        <div>·</div>
        <Badge
          size="sm-md"
          Icon={ContractIcon}
          className={textColorClass.neutral.bolder.enabled}
        >
          <Typography size="sm" color="neutral.bolder.enabled">
            {numContracts} {pluralize("contract", numContracts)}
          </Typography>
        </Badge>
        <div>·</div>
        <Badge
          size="sm-md"
          Icon={GroupRoundedIcon}
          className={textColorClass.neutral.bolder.enabled}
        >
          <Typography size="sm" color="neutral.bolder.enabled">
            {numTeamMembers} team {pluralize("member", numTeamMembers)}
          </Typography>
        </Badge>
      </div>
    </>
  );
}
