import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { createPortal } from "react-dom";

import { useAtom } from "jotai";
import { redirectOnComplete } from "../../components/Account/helpers";
import { PIGGYBACK_REDIRECT_URL } from "../../components/EntityContractsPage/constants";
import { AccountsService } from "../../generated";
import useCookie from "../../hooks/useCookie";
import useRequestID from "../../hooks/useRequestID";
import { modalState } from "../../jotai/page";
import {
  isAuthenticatedState,
  onPostSocialAuthState,
  supplierAccountHandleState,
  supplierProfileState,
  userEmailState,
  userSocialAccountProviderState,
  userTypeSignupState,
} from "../../jotai/user";
import AgencyLandingPageSearchModal from "../../modals/AgencyLandingPageSearchModal";
import BuyerOptInBeforeDownloadModal from "../../modals/BuyerOptInModals/BuyerOptinBeforeDownloadModal";
import BuyerOptInContractSourceModal from "../../modals/BuyerOptInModals/BuyerOptinContractSourceModal";
import BuyerOptInSaveContractModal from "../../modals/BuyerOptInModals/BuyerOptinSaveContractModal";
import ConfirmationModal from "../../modals/ConfirmationModal";
import ContactBuyerLeadAgencyModal from "../../modals/ContactBuyerLeadAgencyModal";
import CreateUnverifiedBLAModal from "../../modals/CreateUnverifiedBLAModal";
import EmailVerificationSuccessModal from "../../modals/EmailVerificationModals/EmailVerificationSuccessModal";
import PleaseVerifyEmailModal from "../../modals/EmailVerificationModals/PleaseVerifyEmailModal";
import ExclusionRemovalModal from "../../modals/ExclusionRemovalModal";
import FeedbackModal from "../../modals/FeedbackModal";
import InviteTeamModal from "../../modals/InviteTeamModal";
import LoginModal from "../../modals/LoginModals";
import LogoUploadModal from "../../modals/LogoUploadModal";
import MessageSupplierExpansionModal from "../../modals/MessageSupplierExpansionModal";
import ConfirmDeleteMessageModal from "../../modals/MessageSupplierModals/ConfirmDeleteMessageModal";
import OtherSuppliersFromListModal from "../../modals/MessageSupplierModals/OtherSuppliersFromListModal";
import OtherSuppliersFromSolicitationModal from "../../modals/MessageSupplierModals/OtherSuppliersFromSolicitationModal";
import SetPasswordModal from "../../modals/PasswordModals";
import ArchiveProjectContractsModal from "../../modals/PiggybackModals/ArchiveProjectContractsModal";
import GetHelpModal from "../../modals/PiggybackModals/GetHelpModal";
import GetHelpSuccessModal from "../../modals/PiggybackModals/GetHelpSuccessModal";
import PiggybackLaunchModal from "../../modals/PiggybackModals/PiggybackLaunchModal";
import RecentlyViewedContractsModal from "../../modals/PiggybackModals/RecentlyViewedContractsModal";
import ProQualificationModal from "../../modals/ProQualificationModal";
import ProductDetailModal from "../../modals/ProductDetailModal";
import ChangeEmailModal from "../../modals/ProfilePageModal/ChangeEmailModal";
import ChangeOnlyAdminModal from "../../modals/ProfilePageModal/ChangeOnlyAdminModal";
import ConfirmChangeAdminModal from "../../modals/ProfilePageModal/ConfirmChangeAdminModal";
import ArchiveProjectReasonModal from "../../modals/ProjectModals/ArchiveProjectReasonModal";
import ArchiveProjectSuccessModal from "../../modals/ProjectModals/ArchiveProjectSuccessModal";
import DeleteProjectConfirmModal from "../../modals/ProjectModals/DeleteProjectConfirmModal";
import EditProjectModal from "../../modals/ProjectModals/EditProjectModal";
import NewProjectModal from "../../modals/ProjectModals/NewProjectModal";
import PostDownloadModal from "../../modals/ProjectModals/PostDownloadModal";
import ProjectInviteModal from "../../modals/ProjectModals/ProjectInviteModal";
import PurchasePathsMethodsModal from "../../modals/PurchasePathsMethodsModal";
import PurchasingGetHelpModal from "../../modals/PurchasingGetHelpModal";
import QuoteRequestModal from "../../modals/QuoteRequestModal";
import SearchDisambiguationModal from "../../modals/SearchDisambiguationModal";
import ShareContractModal from "../../modals/ShareContractModal";
import ShareLinkModal from "../../modals/ShareLinkModal";
import BuyerSignupModal from "../../modals/SignupSteps/SignupModal/BuyerSignupModal";
import InitialSignupModal from "../../modals/SignupSteps/SignupModal/InitialSignupModal";
import PostSocialAuthModal from "../../modals/SignupSteps/SignupModal/PostSocialAuthModal";
import PushToLoginModal from "../../modals/SignupSteps/SignupModal/PushToLoginModal";
import ResetPasswordModal from "../../modals/SignupSteps/SignupModal/ResetPasswordModal";
import SignupStepsSupplierSurveyModal from "../../modals/SignupSteps/SignupModal/SignupStepsSupplierSurveyModal";
import SupplierNameConfirmationModal from "../../modals/SignupSteps/SignupModal/SupplierNameConfirmationModal";
import SupplierSignupModal from "../../modals/SignupSteps/SignupModal/SupplierSignupModal";
import {
  type AccountModalKeys,
  WindowType,
} from "../../modals/SignupSteps/types";
import AddAddressModal from "../../modals/SupplierModals/AddAddressModal";
import AddContactModal from "../../modals/SupplierModals/AddContactModal";
import SupplierPhoneModal from "../../modals/SupplierModals/AddContactModal/SupplierPhoneModal";
import ContractUploadMessageModal from "../../modals/SupplierModals/ContractUploadModal";
import EditAliasModal from "../../modals/SupplierModals/EditAliasModal";
import UploadDocumentsModal from "../../modals/UploadDocumentsModal";
import UploadProductListModal from "../../modals/UploadModals/UploadProductListModal";
import ConfirmSupplierUpgradeInterestModal from "../../modals/welcome/ConfirmSupplierUpgradeInterest";
import Modal from "../../shared/Modal/Modal";
import IntakeEditModal from "../../shared/SearchModal/IntakeEditModal";
import SearchModal from "../../shared/SearchModal/SearchModal";
import { MobileFiltersModal } from "../../shared/SearchPage/SearchResults/MobileFiltersModal";
import type {
  OnFilterChangeFn,
  OnToggleFilterFn,
} from "../../shared/SearchPage/SearchResults/types";
import { NO_MODAL, getDOMAnchorById, validateEmail } from "../../utils";
import {
  COMPANY_NAME,
  SOCIAL_LOGIN_SOURCE_COOKIE_KEY,
} from "../../utils/constants";
import {
  INVITE_TEAM_SOURCE,
  type LoginType,
  MODAL_SOURCE,
  accountModals,
  allAccountModals,
  allModals,
  modalTriggerURLParams,
  modals,
  signupLoginURLParams,
} from "../../utils/enums";
import { SocialProvider } from "../../utils/social";
import {
  trackModalClose,
  trackModalOpen,
  trackSignupLoginExit,
} from "../../utils/tracking";

interface CurrentModal {
  // TODO: Use enum `allModals` instead of any string
  name?: string;
  title?: string;
  subtitle?: string;
  initialEmail?: string;
  socialLoginSource?: SocialProvider | string;
  socialProvider?: string;
  isBlocking?: boolean;
  showGoBackToSearch?: boolean;
  hideModal?: () => void;
  onComplete?: (redirectUrl?: string) => void;
  source?: MODAL_SOURCE;
  inviteSource?: INVITE_TEAM_SOURCE;
  requestID?: string;
  // Mobile filters
  filters?: string[];
  onToggleFilter?: OnToggleFilterFn;
  onFilterChange?: OnFilterChangeFn;
  updateUserState?: () => void;
  isMagicLoginDefault?: boolean;
}

function ModalConductor() {
  const [currentModal, setCurrentModal] = useAtom<CurrentModal>(modalState);
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const onPostSocialAuth = useAtomValue(onPostSocialAuthState);
  const supplierAccountHandle = useAtomValue(supplierAccountHandleState);
  const [socialLoginSource, setSocialLoginSource] = useCookie<SocialProvider>(
    SOCIAL_LOGIN_SOURCE_COOKIE_KEY,
    ""
  );
  const userType = useAtomValue(userTypeSignupState);
  const email = useAtomValue(userEmailState);
  const socialAccountProvider = useAtomValue(userSocialAccountProviderState);
  const supplierProfile = useAtomValue(supplierProfileState);
  const requestID = useRequestID();

  // locks the body to prevent scroll whenever a modal is shown/hidden
  useEffect(() => {
    if (allModals.includes(currentModal?.name || "")) {
      const currentWidth = document.body.offsetWidth;
      document.body.style.overflowY = "hidden";
      // add spacing to prevent body from moving when we hide the scrollbar
      const scrollBarWidth = document.body.offsetWidth - currentWidth;
      document.body.style.marginRight = `${scrollBarWidth}px`;
      if (document.getElementsByTagName("header").length) {
        document.getElementsByTagName("header")[0].style.paddingRight =
          `${scrollBarWidth}px`;
      }
      trackModalOpen({
        modalType: currentModal.name,
        source: currentModal.source,
        requestID,
      });
    } else {
      document.body.style.overflowY = "auto";
      document.body.style.marginRight = "";
      if (document.getElementsByTagName("header").length) {
        document.getElementsByTagName("header")[0].style.paddingRight = "";
      }
    }
  }, [currentModal.name, currentModal.source, requestID]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This opens modals based on url parameters. Run this only once.
  useEffect(() => {
    // Manage triggering modals for different URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get(modalTriggerURLParams.SUPPLIER_SIGNUP)) {
      setCurrentModal({
        name: accountModals.INITIAL_SIGN_UP,
      });
    } else if (urlParams.get(modalTriggerURLParams.SEARCH_MODAL)) {
      setCurrentModal({
        name: modals.SEARCH,
      });
    } else if (
      urlParams.get(modalTriggerURLParams.BUYER_SIGNUP) &&
      !isAuthenticated
    ) {
      const shareOrigin = urlParams.get(signupLoginURLParams.SHARE_ORIGIN);
      const initialEmail = urlParams.get(signupLoginURLParams.EMAIL) || "";
      setCurrentModal({
        name: accountModals.INITIAL_SIGN_UP,
        subtitle: `Finish creating your account to view contract documents and contact suppliers on ${COMPANY_NAME}`,
        showGoBackToSearch: false,
        initialEmail,
        onComplete: shareOrigin
          ? (redirectUrl?: string) => redirectOnComplete(redirectUrl)
          : undefined,
      });
    } else if (urlParams.get(modalTriggerURLParams.LOGIN) && !isAuthenticated) {
      const magicParam = urlParams.get(signupLoginURLParams.MAGIC_LOGIN);
      setCurrentModal({
        name: accountModals.SIGNUP_STEPS_LOGIN,
        isMagicLoginDefault: magicParam === "true",
      });
    } else if (urlParams.get(modalTriggerURLParams.SHOW_PIGGYBACK_MODAL)) {
      if (isAuthenticated) {
        setCurrentModal({
          name: modals.PIGGYBACK_LAUNCH_MODAL,
          source: MODAL_SOURCE.URL_PARAMS,
        });
      } else {
        setCurrentModal({
          name: accountModals.SIGNUP_STEPS_LOGIN,
          onComplete: () => {
            redirectOnComplete(PIGGYBACK_REDIRECT_URL);
          },
        });
      }
    }
  }, []);

  useEffect(() => {
    if (
      isAuthenticated ||
      !onPostSocialAuth ||
      window.location.pathname.startsWith("/accounts")
    ) {
      return;
    }

    setCurrentModal({
      name: accountModals.SIGNUP_POST_SOCIAL_AUTH,
      isBlocking: false,
      showGoBackToSearch: false,
    });
  }, [isAuthenticated, onPostSocialAuth, setCurrentModal]);

  useEffect(() => {
    if (!supplierProfile.needPasswordChange) {
      return;
    }

    setCurrentModal({
      name: accountModals.SET_PASSWORD,
    });
  }, [supplierProfile, setCurrentModal]);

  function reloadPage() {
    window.location.hash = "";
    window.location.reload();
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only show this once.
  useEffect(() => {
    if (
      !socialLoginSource ||
      window.location.pathname.startsWith("/accounts")
    ) {
      return;
    }
    if (isAuthenticated) {
      setSocialLoginSource("" as SocialProvider);
      return;
    }

    if (Object.values(SocialProvider).includes(socialLoginSource)) {
      setCurrentModal({
        name: accountModals.PUSH_TO_LOGIN,
        isBlocking: false,
        hideModal: clearModal,
        onComplete: reloadPage,
        socialLoginSource: socialLoginSource as SocialProvider,
      });
    } else if (validateEmail(socialLoginSource)) {
      setCurrentModal({
        name: accountModals.SIGNUP_STEPS_LOGIN,
        hideModal: clearModal,
        onComplete: reloadPage,
        initialEmail: socialLoginSource,
      });
    }
    setSocialLoginSource("" as SocialProvider);
  }, []);

  function clearModal() {
    if (allAccountModals.includes(currentModal?.name || "")) {
      trackSignupLoginExit({
        emailEntered: email,
        accountType: userType,
        loginType: socialAccountProvider as LoginType,
        loginStage: currentModal.name as AccountModalKeys,
        loginExperience: WindowType.Modal,
      });
    }
    trackModalClose({
      modalType: currentModal.name,
      source: currentModal.source,
    });
    setCurrentModal(NO_MODAL);
  }

  function onSignUp() {
    if (supplierAccountHandle) {
      window.location.href = `/suppliers/${supplierAccountHandle}`;
    } else {
      window.location.href = "/welcome";
    }
  }

  switch (currentModal.name) {
    case modals.EMPTY_MODAL:
      return (
        <Modal hideModal={currentModal.hideModal || clearModal}>
          <div />
        </Modal>
      );
    case accountModals.INITIAL_SIGN_UP:
      return (
        <InitialSignupModal
          hideModal={clearModal}
          onComplete={onSignUp}
          {...currentModal}
        />
      );

    case accountModals.SET_PASSWORD:
      return (
        <SetPasswordModal
          hideModal={clearModal}
          onComplete={reloadPage}
          {...currentModal}
        />
      );

    case accountModals.SUPPLIER_SIGN_UP:
      return (
        <SupplierSignupModal
          hideModal={clearModal}
          onComplete={onSignUp}
          {...currentModal}
        />
      );

    case accountModals.SIGNUP_POST_SOCIAL_AUTH:
      return (
        <PostSocialAuthModal
          hideModal={() => {
            void AccountsService.accountsSocialSignupCancelCreate();
            clearModal();
          }}
          onComplete={onSignUp}
          {...currentModal}
        />
      );

    case accountModals.BUYER_SIGN_UP:
      return (
        <BuyerSignupModal
          hideModal={clearModal}
          onComplete={reloadPage}
          {...currentModal}
        />
      );

    case accountModals.SIGNUP_STEPS_LOGIN:
      return (
        <LoginModal
          hideModal={clearModal}
          onComplete={reloadPage}
          {...currentModal}
        />
      );

    case accountModals.PUSH_TO_LOGIN:
      return (
        <PushToLoginModal
          hideModal={clearModal}
          onComplete={reloadPage}
          socialLoginSource={socialLoginSource}
          {...currentModal}
        />
      );
    case modals.SUPPLIER_SURVEY:
      return (
        // @ts-ignore
        <SignupStepsSupplierSurveyModal
          hideModal={clearModal}
          {...currentModal}
        />
      );
    case accountModals.SUPPLIER_NAME_CONFIRMATION:
      return (
        // @ts-ignore
        <SupplierNameConfirmationModal
          hideModal={clearModal}
          {...currentModal}
        />
      );
    case accountModals.RESET_PASSWORD:
      // @ts-ignore
      return <ResetPasswordModal hideModal={clearModal} {...currentModal} />;
    case accountModals.CHANGE_EMAIL:
      // @ts-ignore
      return <ChangeEmailModal hideModal={clearModal} {...currentModal} />;
    case modals.CONFIRMATION:
      return <ConfirmationModal hideModal={clearModal} {...currentModal} />;
    case modals.CONTACT_BUYER_LEAD_AGENCY:
      return (
        // @ts-ignore
        <ContactBuyerLeadAgencyModal hideModal={clearModal} {...currentModal} />
      );
    case modals.AGENCY_LANDING_PAGE_SEARCH:
      return (
        <AgencyLandingPageSearchModal
          hideModal={clearModal}
          {...currentModal}
        />
      );
    case modals.SEARCH_FEEDBACK:
      return <FeedbackModal hideModal={clearModal} {...currentModal} />;
    case modals.PRO_QUALIFICATION:
      /* @ts-ignore */
      return <ProQualificationModal hideModal={clearModal} {...currentModal} />;
    case modals.CONTRACT_UPLOAD_MESSAGE:
      return (
        // @ts-ignore
        <ContractUploadMessageModal hideModal={clearModal} {...currentModal} />
      );
    case modals.UPLOAD_DOCUMENTS:
      return (
        // @ts-ignore
        <UploadDocumentsModal hideModal={clearModal} {...currentModal} />
      );
    case modals.UPLOAD_PRODUCT_LIST:
      return (
        // @ts-ignore
        <UploadProductListModal hideModal={clearModal} {...currentModal} />
      );
    case modals.ADD_CONTACT:
      // @ts-ignore
      return <AddContactModal hideModal={clearModal} {...currentModal} />;
    case modals.ADD_LOCATION:
      // @ts-ignore
      return <AddAddressModal hideModal={clearModal} {...currentModal} />;
    case modals.EDIT_ALIAS:
      // @ts-ignore
      return <EditAliasModal hideModal={clearModal} {...currentModal} />;
    case modals.SUPPLIER_PHONE:
      return <SupplierPhoneModal hideModal={clearModal} {...currentModal} />;
    case modals.SEARCH:
      // @ts-ignore
      return <SearchModal hideModal={clearModal} {...currentModal} />;
    case modals.INVITE_TEAM:
      return (
        <InviteTeamModal
          hideModal={clearModal}
          inviteSource={currentModal.inviteSource || INVITE_TEAM_SOURCE.UNKNOWN}
          {...currentModal}
        />
      );
    case modals.SHARE_LINK:
      // @ts-ignore
      return <ShareLinkModal hideModal={clearModal} {...currentModal} />;
    case modals.SHARE_CONTRACT:
      return (
        // @ts-ignore
        <ShareContractModal hideModal={clearModal} {...currentModal} />
      );
    case modals.LOGO_UPLOAD:
      // @ts-ignore
      return <LogoUploadModal hideModal={clearModal} {...currentModal} />;
    case modals.CONFIRM_DELETE_MESSAGE:
      return (
        // @ts-ignore
        <ConfirmDeleteMessageModal hideModal={clearModal} {...currentModal} />
      );
    case modals.MESSAGE_OTHER_SUPPLIERS_FROM_SOLICITATION:
      return (
        // @ts-ignore
        <OtherSuppliersFromSolicitationModal
          hideModal={clearModal}
          {...currentModal}
        />
      );
    case modals.MESSAGE_OTHER_SUPPLIERS_FROM_LIST:
      return (
        // @ts-ignore
        <OtherSuppliersFromListModal hideModal={clearModal} {...currentModal} />
      );
    case modals.PLEASE_VERIFY_EMAIL_MODAL:
      // @ts-ignore
      return (
        <PleaseVerifyEmailModal hideModal={clearModal} {...currentModal} />
      );
    case modals.EMAIL_VERIFICATION_SUCCESS:
      return (
        <EmailVerificationSuccessModal
          hideModal={clearModal}
          {...currentModal}
        />
      );
    case modals.FILTERS:
      // @ts-ignore
      return <MobileFiltersModal hideModal={clearModal} {...currentModal} />;
    case modals.NEW_PROJECT_MODAL:
      // @ts-ignore
      return <NewProjectModal hideModal={clearModal} {...currentModal} />;
    case modals.POST_DOWNLOAD_MODAL:
      // @ts-ignore
      return <PostDownloadModal hideModal={clearModal} {...currentModal} />;
    case modals.PROJECT_INVITE_MODAL:
      return (
        // @ts-ignore
        <ProjectInviteModal hideModal={clearModal} {...currentModal} />
      );
    case modals.EDIT_PROJECT_MODAL:
      return (
        // @ts-ignore
        <EditProjectModal hideModal={clearModal} {...currentModal} />
      );
    case modals.ARCHIVE_PROJECT_CONTRACTS_MODAL:
      return (
        // @ts-ignore
        <ArchiveProjectContractsModal
          hideModal={clearModal}
          {...currentModal}
        />
      );
    case modals.ARCHIVE_PROJECT_REASON_MODAL:
      return (
        // @ts-ignore
        <ArchiveProjectReasonModal hideModal={clearModal} {...currentModal} />
      );
    case modals.ARCHIVE_PROJECT_SUCCESS_MODAL:
      return (
        // @ts-ignore
        <ArchiveProjectSuccessModal hideModal={clearModal} {...currentModal} />
      );
    case modals.DELETE_PROJECT_CONFIRM:
      return (
        // @ts-ignore
        <DeleteProjectConfirmModal hideModal={clearModal} {...currentModal} />
      );
    case modals.EXCLUSION_REMOVAL_MODAL:
      return (
        // @ts-ignore
        <ExclusionRemovalModal hideModal={clearModal} {...currentModal} />
      );
    case modals.PIGGYBACK_LAUNCH_MODAL:
      return (
        <PiggybackLaunchModal
          hideModal={clearModal}
          // biome-ignore lint/style/noNonNullAssertion: We are certain that the modal will have these props
          updateUserState={currentModal.updateUserState!}
          {...currentModal}
        />
      );
    case modals.RECENTLY_VIEWED_CONTRACTS_MODAL:
      return (
        <RecentlyViewedContractsModal
          hideModal={clearModal}
          onComplete={() => currentModal.onComplete?.()}
          {...currentModal}
        />
      );
    case modals.GET_HELP_MODAL:
      return <GetHelpModal hideModal={clearModal} {...currentModal} />;
    case modals.GET_HELP_SUCCESS_MODAL:
      return <GetHelpSuccessModal hideModal={clearModal} {...currentModal} />;
    case modals.BUYER_OPT_IN_BEFORE_DOWNLOAD_MODAL:
      return (
        // @ts-ignore
        <BuyerOptInBeforeDownloadModal
          hideModal={clearModal}
          modalType="BUYER_OPT_IN_BEFORE_DOWNLOAD_MODAL"
          {...currentModal}
        />
      );
    case modals.BUYER_OPT_IN_CONTRACT_SOURCE_MODAL:
      return (
        // @ts-ignore
        <BuyerOptInContractSourceModal
          hideModal={clearModal}
          modalType="BUYER_OPT_IN_CONTRACT_SOURCE_MODAL"
          {...currentModal}
        />
      );
    case modals.BUYER_OPT_IN_SAVE_CONTRACT_MODAL:
      return (
        // @ts-ignore
        <BuyerOptInSaveContractModal
          hideModal={clearModal}
          modalType="BUYER_OPT_IN_SAVE_CONTRACT_MODAL"
          {...currentModal}
        />
      );
    case modals.CHANGE_ONLY_ADMIN_MODAL:
      return (
        // @ts-ignore
        <ChangeOnlyAdminModal hideModal={clearModal} {...currentModal} />
      );
    case modals.CONFIRM_CHANGE_ADMIN_MODAL:
      return (
        <ConfirmChangeAdminModal hideModal={clearModal} {...currentModal} />
      );
    case modals.PRODUCT_DETAIL_MODAL:
      // @ts-ignore
      return <ProductDetailModal hideModal={clearModal} {...currentModal} />;
    case modals.CONFIRM_SUPPLIER_UPGRADE_INTEREST_SUCCESS:
      return (
        // @ts-ignore
        <ConfirmSupplierUpgradeInterestModal
          hideModal={clearModal}
          {...currentModal}
        />
      );
    case modals.SEARCH_DISAMBIGUATION:
      return (
        // @ts-ignore
        <SearchDisambiguationModal hideModal={clearModal} {...currentModal} />
      );
    case modals.MESSAGE_SUPPLIER_EXPANSION:
      return (
        // @ts-ignore
        <MessageSupplierExpansionModal
          hideModal={clearModal}
          {...currentModal}
        />
      );
    case modals.QUOTE_REQUEST:
      // @ts-ignore
      return <QuoteRequestModal hideModal={clearModal} {...currentModal} />;
    case modals.CREATE_UNVERIFIED_BLA:
      return (
        // @ts-ignore
        <CreateUnverifiedBLAModal hideModal={clearModal} {...currentModal} />
      );
    case modals.PURCHASE_PATHS_METHODS:
      return (
        // @ts-ignore
        <PurchasePathsMethodsModal hideModal={clearModal} {...currentModal} />
      );
    case modals.PURCHASING_GET_HELP:
      return <PurchasingGetHelpModal hideModal={clearModal} />;
    case modals.INTAKE_EDIT:
      // @ts-ignore
      return <IntakeEditModal hideModal={clearModal} {...currentModal} />;
  }
  return null;
}

export default function ModalPortal() {
  const container = getDOMAnchorById("modal-container");
  if (!container) return null;
  return createPortal(<ModalConductor />, container);
}
