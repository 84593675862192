import clsx from "clsx";
import { useAtomValue } from "jotai";
import Pill from ".";
import type { SupplierListResponse } from "../../generated";
import useRequestID from "../../hooks/useRequestID";
import { projectContextState } from "../../jotai/projects";
import { searchQueryState } from "../../jotai/search";
import { userZipState } from "../../jotai/user";
import { Typography } from "../../library";
import { goToURL } from "../../utils";
import { bgColorClass, borderColorClass } from "../../utils/colors";
import type { pageNavigationSourceTypes } from "../../utils/enums";
import { getSupplierUrlPath } from "../../utils/format";
import { trackViewSupplierProfile } from "../../utils/tracking";

export function SavedSuppliersList({
  suppliers,
  pageNavigationSource,
  onDelete,
}: {
  suppliers: SupplierListResponse[];
  pageNavigationSource: pageNavigationSourceTypes;
  onDelete: (supplierId: number) => void;
}) {
  const requestID = useRequestID();
  const userZip = useAtomValue(userZipState);
  const projectContext = useAtomValue(projectContextState);
  const query = useAtomValue(searchQueryState);

  return (
    <div className="flex flex-row flex-wrap gap-2">
      {suppliers.length ? (
        suppliers.map(({ supplier }) => (
          <Pill
            key={supplier.id}
            className={clsx(
              "border border-solid",
              bgColorClass.neutral.subtlest.enabled,
              borderColorClass.neutral.subtle.enabled
            )}
            onClick={() => {
              const supplierUrl = getSupplierUrlPath({
                handle: supplier.handle,
                query,
                zip: userZip,
                pageNavigationSource,
                requestID,
                projectId: projectContext?.id,
                ctaType: null,
                searchType: null,
              }).href;
              trackViewSupplierProfile({
                supplierHandle: supplier.handle,
                supplierId: supplier.id,
                pageNavigationSource,
                supplierName: supplier.displayName,
                supplierUrl,
              });
              goToURL(supplierUrl);
            }}
            onClickIcon={() => {
              onDelete(supplier.id);
            }}
          >
            {supplier.displayName}
          </Pill>
        ))
      ) : (
        <Typography variant="body" size="sm" color="neutral.bold.enabled">
          Save suppliers to quickly send messages and get quotes.
        </Typography>
      )}
    </div>
  );
}
