import { useAtom } from "jotai";
import { useMemo } from "react";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import { projectContextState } from "../../../jotai/projects";
import {
  Button,
  ButtonSizes,
  CardContainer,
  CardContainerVariant,
  Link,
  Typography,
} from "../../../library";
import { useEditProjectModal } from "../../../modals/ProjectModals/EditProjectModal";
import GetHelpNotice from "../../../shared/GetHelpNotice";
import { useIntakeEditModal } from "../../../shared/SearchModal/IntakeEditModal";
import { formatPurchaseCostRange, getSentenceCase } from "../../../utils";
import { borderColorClass } from "../../../utils/colors";
import type { SearchFilter } from "../../../utils/enums";

export default function ProjectOverviewDetails() {
  const [projectContext, setProjectContext] = useAtom(projectContextState);
  const showModal = useEditProjectModal();
  const showEditModal = useIntakeEditModal();
  const search = useSearchContractWithParams();

  const items = useMemo(() => {
    if (!projectContext) return [];
    if (!projectContext.purchaseRequest)
      return [{ label: "Need", value: projectContext.name }];

    const {
      requestDescription,
      category,
      costRange,
      selectedOption,
      purchaseQuestionAnswers,
    } = projectContext.purchaseRequest;

    const items: Array<{
      label: string;
      value: string | JSX.Element;
    }> = [
      { label: "Need", value: requestDescription },
      { label: "Category", value: category.label },
      {
        label: "Budget",
        value: formatPurchaseCostRange(costRange),
      },
    ];

    if (selectedOption) {
      items.push({
        label: "Method",
        value: getSentenceCase(selectedOption.title),
      });
    } else {
      items.push({
        label: "Method",
        value: (
          <Link
            dataTestId="select-purchase-method"
            size="sm"
            emphasis={false}
            href="#search"
            target="_parent"
          >
            Select purchase method
          </Link>
        ),
      });
    }

    for (const answer of purchaseQuestionAnswers) {
      items.push({
        label: answer.question.label,
        value: answer.booleanValue ? "Yes" : "No",
      });
    }
    return items;
  }, [projectContext]);

  if (!projectContext) return;

  return (
    <CardContainer
      className="p-6 gap-4"
      variant={CardContainerVariant.SECONDARY}
    >
      <Typography variant="headline" size="sm" emphasis>
        About your purchase
      </Typography>
      <div className="flex flex-col gap-3">
        {items.map(({ label, value }, ix) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: Order is static, so index is ok
          <div key={ix} className="flex gap-4">
            <Typography
              emphasis
              size="sm"
              color="neutral.bolder.enabled"
              className={label.length <= 12 ? "flex-none w-22" : ""}
            >
              {label}
            </Typography>
            <Typography size="sm" color="neutral.bolder.enabled">
              {value}
            </Typography>
          </div>
        ))}
      </div>
      <div>
        <Button
          name="edit-request"
          dataTestId="edit-request"
          size={ButtonSizes.SMALL}
          onClick={() => {
            if (projectContext?.purchaseRequest) {
              showEditModal({
                onSave: ({
                  selectedOption,
                  requestDescription,
                  supplierIds,
                }) => {
                  if (!selectedOption || !selectedOption.searchType) {
                    search({
                      newParams: {
                        query: requestDescription,
                        supplierIds,
                      },
                    });
                    return;
                  }

                  search({
                    newParams: {
                      query: requestDescription,
                      searchType: selectedOption.searchType,
                      supplierIds,
                      filters: selectedOption.searchFilters as SearchFilter[],
                    },
                  });
                },
              });
            } else {
              showModal({
                project: projectContext,
                onComplete: (p) =>
                  setProjectContext((prev) => ({ ...prev, ...p })),
              });
            }
          }}
        >
          Edit request info
        </Button>
      </div>
      <hr className={borderColorClass.neutral.subtle.enabled} />
      <GetHelpNotice subtitle="Reach out to our procurement contact regarding questions with this purchase." />
    </CardContainer>
  );
}
