import { type Getter, type Setter, atom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useEffect } from "react";
import { ApiService, type SupplierContractSearchResponse } from "../generated";
import { matchesSupplier } from "../hooks/search/utils";
import { handleError } from "../utils/generatedApi";

const supplierContractSearchInitializedState = atom(false);

const supplierContractSearchLoadingState = atom(false);

export const supplierContractLastSearchQueryState = atom("");

const DEFAULT_SUPPLIER_CONTRACT_RESPONSE: SupplierContractSearchResponse = {
  contractsTotalCount: 0,
  results: [],
  sources: {
    agencies: [],
    cooperatives: [],
    localAgencies: [],
  },
  page: 0,
  pageSize: 10,
};

const initialSupplierContractResponseState =
  atom<SupplierContractSearchResponse>(DEFAULT_SUPPLIER_CONTRACT_RESPONSE);

const supplierContractSearchResponseState =
  atom<SupplierContractSearchResponse>(DEFAULT_SUPPLIER_CONTRACT_RESPONSE);

export const supplierContractSearchState = atom(
  (get) => {
    return {
      isInitialized: get(supplierContractSearchInitializedState),
      isLoading: get(supplierContractSearchLoadingState),
      initialSupplierContracts: get(initialSupplierContractResponseState),
      supplierContracts: get(supplierContractSearchResponseState),
      lastSearchQuery: get(supplierContractLastSearchQueryState),
    };
  },
  (
    _get,
    set,
    value: {
      isInitialized: boolean;
      isLoading: boolean;
      supplierContracts: SupplierContractSearchResponse;
      lastSearchQuery: string;
    }
  ) => {
    set(supplierContractSearchInitializedState, value.isInitialized);
    set(supplierContractSearchLoadingState, value.isLoading);
    set(supplierContractSearchResponseState, value.supplierContracts);
    set(supplierContractLastSearchQueryState, value.lastSearchQuery);
  }
);

export function useSearchSupplierContractsCallback() {
  const searchSupplierContracts = useCallback(
    async (
      _get: Getter,
      set: Setter,
      supplierId: number,
      page: number,
      filters?: Array<string>,
      query?: string
    ) => {
      try {
        set(supplierContractSearchLoadingState, true);
        const response = await ApiService.apiV1SuppliersContractSearchRetrieve(
          supplierId,
          filters,
          page,
          undefined,
          query
        );
        set(supplierContractSearchResponseState, response);
        set(supplierContractLastSearchQueryState, query || "");
        set(supplierContractSearchLoadingState, false);
        return response;
      } catch (error) {
        handleError(error);
        set(supplierContractSearchResponseState, {
          ...DEFAULT_SUPPLIER_CONTRACT_RESPONSE,
        });
        set(supplierContractSearchLoadingState, false);
      }
    },
    []
  );
  return useAtomCallback(searchSupplierContracts);
}

function useInitializeSupplierContractSearchCallback() {
  const search = useSearchSupplierContractsCallback();

  const initialize = useCallback(
    async (
      get: Getter,
      set: Setter,
      supplierId: number,
      query: string,
      filters?: Array<string>
    ) => {
      const supplierContractSearch = get(supplierContractSearchState);
      if (supplierContractSearch.isInitialized) return;

      const response = await search(supplierId, 0, filters, query);
      if (!response?.contractsTotalCount) {
        await search(supplierId, 0, filters, "");
      } else {
        set(initialSupplierContractResponseState, response);
      }
      set(supplierContractSearchInitializedState, true);
    },
    [search]
  );
  return useAtomCallback(initialize);
}

export default function useInitializeSupplierContractSearch({
  showSupplierContracts,
  supplierId,
  query,
  handle,
  name,
  filters,
}: {
  showSupplierContracts: boolean;
  supplierId: number;
  query: string;
  handle: string;
  name: string;
  filters: Array<string> | undefined;
}) {
  const initializeCallback = useInitializeSupplierContractSearchCallback();

  useEffect(() => {
    if (!showSupplierContracts) return;

    const includesSupplierName = matchesSupplier(query, handle, name);
    initializeCallback(supplierId, includesSupplierName ? "" : query, filters);
  }, [
    showSupplierContracts,
    supplierId,
    query,
    initializeCallback,
    handle,
    name,
    filters,
  ]);
}
